import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Missing from "layouts/404";
import RequiredAuth from "middlewares/hooks/requiredLogin";
import PersitLogin from "middlewares/global/persistentLogin";
import LoginUser from "pages/login/login";
import DashBoard from "pages/dashboard/dashboard";
import Clients from "pages/clients/client";
import ClientsBranch from "pages/clients/clientBranch";
import ClientSingle from "pages/clients/client-single";
import LoanApplications from "pages/loans/loan-applications";
import LoanApplicationSingle from "pages/loans/loan-application-single";
import ActiveLoans from "pages/loans/active-loans";
import LoanActiveSingle from "pages/loans/loan-active-single";
import SettledLoans from "pages/loans/settled-loans";
import Overdue from "pages/loans/overdue-loans";
import WaivedOff from "pages/loans/waived-off-loans";
import WritternOff from "pages/loans/writtern-off-loans";
import LoanApplicationsBranch from "pages/loans/branch-loans/loan-applications";
import ActiveLoansBranch from "pages/loans/branch-loans/active-loans";
import SettledLoansBranch from "pages/loans/branch-loans/settled-loans";
import OverdueBranch from "pages/loans/branch-loans/overdue-loans";
import WaivedOffBranch from "pages/loans/branch-loans/waived-off-loans";
import WritternOffBranch from "pages/loans/branch-loans/writtern-off-loans";
import AccountVotes from "pages/settings/accounts/accounts";
import ClientGroups from "pages/settings/client-groups/client-groups";
import Branches from "pages/settings/branches/branches";
import Roles from "pages/settings/roles/roles";
import AccountProfile from "pages/profile-accounts/account-profile";
import StaffAccounts from "pages/profile-accounts/accounts";
import HealthSummary from "pages/health/summary";
import BulkSMS from "pages/communication/bulk-sms";
import Emails from "pages/communication/email";
import Assets from "pages/accounting/assets";
import Transactions from "pages/accounting/transactions";
import FixedDeposits from "pages/accounting/fixedDeposits";
import Incomes from "pages/accounting/income";
import Expenses from "pages/accounting/expense";
import AccountingHome from "pages/reports/accounting/accounting";
import CashBook from "pages/reports/accounting/components/accounting/cashbook";
import GeneralLedger from "pages/reports/accounting/components/accounting/general-ledger";
import TrialBalance from "pages/reports/accounting/components/accounting/trial-balance";
import LoansHome from "pages/reports/accounting/loans-section";
import CommunicationHome from "pages/reports/accounting/communication";
import IncomeStatement from "pages/reports/accounting/components/accounting/income-statement";
import BalanceSheet from "pages/reports/accounting/components/accounting/balance-sheet";
import PaymentTransactionsReport from "pages/reports/accounting/components/accounting/payment-transactions";
import IncomeTransactionsReport from "pages/reports/accounting/components/accounting/income-transactions";
import ExpenseTransactionsReport from "pages/reports/accounting/components/accounting/expenses-transactions";
import ActiveLoansReport from "pages/reports/accounting/components/loans/active-loans-report";
import PendingLoansReport from "pages/reports/accounting/components/loans/pending-loans-report";
import OverdueLoansReport from "pages/reports/accounting/components/loans/overdue-loans-report";
import SettledLoansReport from "pages/reports/accounting/components/loans/settled-loans-report";
import AgingLoansReport from "pages/reports/accounting/components/loans/aging-loans-report";
import LoansBalancesReport from "pages/reports/accounting/components/loans/loan-balances-loans-report";
import InterestOnLoans from "pages/reports/accounting/components/loans/ineterest-loans-report";
import LoansFeesReport from "pages/reports/accounting/components/loans/loans-loans-report ";
import AuthLayout from "layouts/auth-layout";
import Unauthorized from "layouts/unauthorized";
import { useState } from "react";
import ClientAllBranches from "pages/clients/clientBranchAll";
import LoanApplicationBranches from "pages/loans/branch-loans/loan-applications-all";
import ActiveLoansBranches from "pages/loans/branch-loans/active-loans-all";
import OverdueLoansBranches from "pages/loans/branch-loans/overdue-loans-all";
import SettledLoansBranches from "pages/loans/branch-loans/settled-loans-all";
import WritternLoansBranches from "pages/loans/branch-loans/writtern-off-loans-all";
import BranchManagerDasboard from "pages/dashboard/dashboard/branch";
// import RegisterDevice from "pages/login/register-device";

function App() {
  const [roles, setRoles] = useState([]);

  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          {/* <Route path="/" index element={<RegisterDevice />} /> */}
          <Route path="/" element={<LoginUser setRoles={setRoles} />} />
          <Route path="/unathorized" element={<Unauthorized />} />

          <Route element={<PersitLogin setRoles={setRoles} />}>
            <Route element={<AuthLayout />}>
              <Route element={<RequiredAuth allowedRoles={[1000001]} />}>
                <Route
                  path="/dashboard"
                  element={<DashBoard roles={roles} />}
                />
                <Route
                  path="/dashboard/branch/:id"
                  element={<BranchManagerDasboard roles={roles} />}
                />
              </Route>

              {/* clients routes start */}
              <Route element={<RequiredAuth allowedRoles={[1700352]} />}>
                <Route
                  path="/clients/general"
                  element={<Clients roles={roles} />}
                />
              </Route>
              <Route element={<RequiredAuth allowedRoles={[10000021]} />}>
                <Route
                  path="/clients/branch"
                  element={<ClientAllBranches roles={roles} />}
                />
                <Route
                  path="/clients/branch/:id"
                  element={<ClientsBranch roles={roles} />}
                />
              </Route>
              <Route element={<RequiredAuth allowedRoles={[10000021]} />}>
                <Route
                  path="/clients/:id"
                  element={<ClientSingle roles={roles} />}
                />
              </Route>
              {/* client routes end */}
              {/* loans routes start */}
              <Route element={<RequiredAuth allowedRoles={[1004004]} />}>
                <Route
                  path="/applications/general"
                  element={<LoanApplications roles={roles} />}
                />
                <Route element={<RequiredAuth allowedRoles={[10000047]} />}>
                  <Route
                    path="/active/general"
                    element={<ActiveLoans roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000049]} />}>
                  <Route
                    path="/settled/general"
                    element={<SettledLoans roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000048]} />}>
                  <Route
                    path="/overdue/general"
                    element={<Overdue roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000050]} />}>
                  <Route
                    path="/waived/general"
                    element={<WaivedOff roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000051]} />}>
                  <Route
                    path="/writtern/general"
                    element={<WritternOff roles={roles} />}
                  />
                </Route>
              </Route>
              <Route element={<RequiredAuth allowedRoles={[10000041]} />}>
                <Route
                  path="/applications/branch"
                  element={<LoanApplicationBranches roles={roles} />}
                />

                <Route
                  path="/applications/branch/:id"
                  element={<LoanApplicationsBranch roles={roles} />}
                />
                <Route element={<RequiredAuth allowedRoles={[10000048]} />}>
                  <Route
                    path="/overdue/branch"
                    element={<OverdueLoansBranches roles={roles} />}
                  />
                  <Route
                    path="/overdue/branch/:id"
                    element={<OverdueBranch roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000047]} />}>
                  <Route
                    path="/active/branch"
                    element={<ActiveLoansBranches roles={roles} />}
                  />
                  <Route
                    path="/active/branch/:id"
                    element={<ActiveLoansBranch roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000049]} />}>
                  <Route
                    path="/settled/branch"
                    element={<SettledLoansBranches roles={roles} />}
                  />
                  <Route
                    path="/settled/branch/:id"
                    element={<SettledLoansBranch roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000050]} />}>
                  <Route
                    path="/waived/branch"
                    element={<WaivedOffBranch roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[10000051]} />}>
                  <Route
                    path="/writtern/branch"
                    element={<WritternLoansBranches roles={roles} />}
                  />
                  <Route
                    path="/writtern/branch/:id"
                    element={<WritternOffBranch roles={roles} />}
                  />
                </Route>
              </Route>
              <Route element={<RequiredAuth allowedRoles={[10000042]} />}>
                <Route
                  path="/loans/:id"
                  element={<LoanActiveSingle roles={roles} />}
                />
              </Route>
              <Route element={<RequiredAuth allowedRoles={[10000043]} />}>
                <Route
                  path="/applications/:id"
                  element={<LoanApplicationSingle roles={roles} />}
                />
              </Route>
              {/* loans end routes */}
              {/* settings routes start  */}
              <Route element={<RequiredAuth allowedRoles={[1003]} />}>
                <Route path="/settings/votes" element={<AccountVotes />} />
                <Route
                  path="/settings/clients/groups"
                  element={<ClientGroups />}
                />
                <Route path="/settings/branches" element={<Branches />} />
                <Route path="/settings/roles" element={<Roles />} />
              </Route>
              {/* settings end  */}
              {/* start accounts */}
              <Route path="/accounts/profile" element={<AccountProfile />} />
              <Route element={<RequiredAuth allowedRoles={[1002]} />}>
                <Route path="/accounts/users" element={<StaffAccounts />} />
              </Route>
              {/* end accounts */}
              {/* system health */}
              <Route element={<RequiredAuth allowedRoles={[1005]} />}>
                <Route path="/system/health" element={<HealthSummary />} />
              </Route>
              {/* end of system health */}
              {/* start communication */}
              <Route element={<RequiredAuth allowedRoles={[1004]} />}>
                <Route path="/communication/bulk-sms" element={<BulkSMS />} />
                <Route path="/communication/emails" element={<Emails />} />
              </Route>
              {/* communication end */}
              {/* accounting start */}
              <Route element={<RequiredAuth allowedRoles={[10000100]} />}>
                <Route element={<RequiredAuth allowedRoles={[100000101]} />}>
                  <Route
                    path="/accounting/assets"
                    element={<Assets roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[100000107]} />}>
                  <Route
                    path="/accounting/transactions"
                    element={<Transactions roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[100000104]} />}>
                  <Route
                    path="/accounting/fixed-deposits"
                    element={<FixedDeposits roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[100000108]} />}>
                  <Route
                    path="/accounting/incomes"
                    element={<Incomes roles={roles} />}
                  />
                </Route>
                <Route element={<RequiredAuth allowedRoles={[100000102]} />}>
                  <Route
                    path="/accounting/expenses"
                    element={<Expenses roles={roles} />}
                  />
                </Route>
              </Route>
              {/* accounting end */}
              {/* reports start */}
              {/* accounting reports */}
              <Route element={<RequiredAuth allowedRoles={[1001]} />}>
                <Route
                  path="/reports/accounting"
                  element={<AccountingHome />}
                />
                <Route path="/reports/loans" element={<LoansHome />} />

                <Route
                  path="/reports/communication"
                  element={<CommunicationHome />}
                />
                <Route
                  path="/reports/accounting/cashbook"
                  element={<CashBook />}
                />
                <Route
                  path="/reports/accounting/general-ledger"
                  element={<GeneralLedger />}
                />
                <Route
                  path="/reports/accounting/trial-balance"
                  element={<TrialBalance />}
                />
                <Route
                  path="/reports/accounting/income-statement"
                  element={<IncomeStatement />}
                />
                <Route
                  path="/reports/accounting/balance-sheet"
                  element={<BalanceSheet />}
                />
                <Route
                  path="/reports/accounting/payment-transactions"
                  element={<PaymentTransactionsReport />}
                />
                <Route
                  path="/reports/accounting/incomes-general"
                  element={<IncomeTransactionsReport />}
                />
                <Route
                  path="/reports/accounting/expenses-general"
                  element={<ExpenseTransactionsReport />}
                />
                {/* accounting reports end */}
                <Route
                  path="/reports/loans/active-loans"
                  element={<ActiveLoansReport />}
                />
                <Route
                  path="/reports/loans/pending-loans"
                  element={<PendingLoansReport />}
                />
                <Route
                  path="/reports/loans/overdue-loans"
                  element={<OverdueLoansReport />}
                />
                <Route
                  path="/reports/loans/settled-loans"
                  element={<SettledLoansReport />}
                />
                <Route
                  path="/reports/loans/aging-loans"
                  element={<AgingLoansReport />}
                />
                <Route
                  path="/reports/loans/loan-balances"
                  element={<LoansBalancesReport />}
                />
                <Route
                  path="/reports/loans/interest-loans"
                  element={<InterestOnLoans />}
                />
                <Route
                  path="/reports/loans/loan-fees"
                  element={<LoansFeesReport />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
