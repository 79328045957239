const  NoDashboard= () => {
    return (
      <>
        <div className="container-fluid">
          <div className="row  mt-md-0 mt-5">
            <div className="col-md-12 col-sm-12">
              <div className="card border-0 zoom-in bg-light-primary shadow-none">
                <div className="card-body">
                  <div className="text-center">You No Permission To View Dashboards</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
 
export default NoDashboard;