
const Footer = () => {
  return (
    <>
      <footer className="footer fixed-bottom p-2 bg-white" style={{zIndex: "1"}}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-10">
              <div className="copyright text-end text-sm text-dark">
                <p className="font-weight-bold text-small">
                  &copy; {new Date().getFullYear()} Furaha Link Ventures
                </p>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="copyright text-end text-sm text-dark">
                <p className="font-weight-bold text-small">
                  Powered by <a href="https://furahatechnologies.com/" target="_blank" rel="noreferrer" className="text-primary">
                  Furaha Technologies
                  </a> 
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
