import GeneralManagerDashboard from "./dashboard/general";
import NoDashboard from "./dashboard/nodashboard";
import DashboardBranches from "./dashboard/dashboard_branch";

const DashBoard = ({roles}) => {
  
  return (
    <>
      {roles?.includes(100000112) && <GeneralManagerDashboard />}
      {roles?.includes(10000012) && <DashboardBranches />}
      {!(roles?.includes(100000112) || roles?.includes(10000012)) && (
        <NoDashboard />
      )}
    </>
  );
};

export default DashBoard;
