import { useEffect, useState, useMemo } from "react";
import Loader from "services/loaders/loader";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Table from "pages/components/common_table";
import MonthlyPayments from "../components/monthlyPaymentsLineGraph";
import MonthlyLoansDisbursed from "../components/monthlyLoansBarGraph";
import ClientsPieChart from "../components/clientsPieChart";
const BranchManagerDashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const controller = new AbortController();
  const [transactions, setTransactions] = useState([]);
  const params = useParams();
  const getDashboardValues = async () => {
    try {
      const response = await axiosPrivate.get(`/dashboard/${params.id}`, {
        signal: controller.signal,
      });
      console.log(response.data.data);
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getTransansactionsData = async () => {
    try {
      const response = await axiosPrivate.get(`/transactions/branch/${params.id}`, {
        signal: controller.signal,
      });
      // console.log(response.data.data.transactions);
      setTransactions(response.data.data.transactions);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const [loans, setLoans] = useState([]);
  const getDisurbsedLoans = async () => {
    try {
      const response = await axiosPrivate.get(`/branch/loans/${params.id}`, {
        signal: controller.signal,
      });
      // console.log(response.data.data.transactions);
      setLoans(response.data.data.loanApps);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const [clients, setClients] = useState([]);
  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("/clients", {
        signal: controller.signal,
      });
      // console.log(response.data.data.transactions);
      setClients(response.data.data.clients);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    getDashboardValues();
    getTransansactionsData();
    getDisurbsedLoans();
    getClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refetch = () => {
    setIsLoading(false);
    getDashboardValues();
    getTransansactionsData();
    getDisurbsedLoans();
  };

  const columns = useMemo(
    () => [
      {
        id: "client", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client Name",
        accessorFn: (row) => `${row.client}`,
      },

      {
        id: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Client Contact",
        accessorFn: (row) => `${row.client_contact}`,
      },
      {
        id: "princiapl", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Principal",
        accessorFn: (row) => `${row.principal.toLocaleString()}`,
      },
      {
        id: "due_amount", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Amount Due",
        accessorFn: (row) => `${row.amount_been_paid.toLocaleString()}`,
      },
      {
        id: "action", //access nested data with dot notation
        enableClickToCopy: true,
        size: 50,
        header: "Action",
        accessorFn: (row) => <Link to={`/loans/${row.id}`}>More</Link>,
      },
    ],
    []
  );
  return (
    <>
      <div className="container-fluid">
        <div className="row  mt-md-0 mt-5">
          <div className="col-md-6 col-sm-12">
            <div className="card border-0 zoom-in bg-primary shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">Clients</p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <h5 className="fw-semibold text-white mb-0">
                      {data?.clients}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="card border-0 zoom-in bg-danger shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">Groups</p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <h5 className="fw-semibold text-white mb-0">
                      {data?.groups}
                    </h5>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="card w-100 bg-light-white overflow-hidden shadow-none">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-md-6 card">
                    <div className="card-header h5 text-center">
                      Monthly Payments
                    </div>
                    <div className="card-body p-0">
                      {transactions && (
                        <MonthlyPayments transactions={transactions} />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 card">
                    <div className="card-header h5 text-center">
                      Monthly Loans Disbursed
                    </div>
                    <div className="card-body p-0">
                      {loans && <MonthlyLoansDisbursed transactions={loans} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row  mt-md-0 mt-5">
          <div className="col-md-4 col-sm-12">
            <div className="card border-0 zoom-in bg-success shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">
                    {" "}
                    Active Loans{" "}
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="fw-semibold text-white mb-0">
                        {data?.active_loans ?? 0}
                      </h5>
                      <h6 className="fw-semibold text-white mb-0 pt-3">
                        UGX {data?.active_loan_amount?.toLocaleString() ?? 0}{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card border-0 zoom-in bg-danger shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">
                    {" "}
                    Overdue Loans{" "}
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="fw-semibold text-white mb-0">
                        {data?.overdue_loans ?? 0}
                      </h5>
                      <h6 className="fw-semibold text-white mb-0 pt-3">
                        UGX {data?.overdue_loan_amount?.toLocaleString() ?? 0}{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card border-0 zoom-in bg-primary shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">
                    {" "}
                    Pending Loans{" "}
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="fw-semibold text-white mb-0">
                        {data?.pending_loans ?? 0}
                      </h5>
                      <h6 className="fw-semibold text-white mb-0 pt-3">
                        UGX {data?.pending_loan_amount?.toLocaleString() ?? 0}{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card border-0 zoom-in bg-warning shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">
                    Waived Off loans
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="fw-semibold text-white mb-0">
                        {data?.stopped_loans ?? 0}
                      </h5>
                      <h6 className="fw-semibold text-white mb-0 pt-3">
                        UGX {data?.stopped_loan_amount?.toLocaleString() ?? 0}{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card border-0 zoom-in bg-success shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">
                    Settled Loans
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="fw-semibold text-white mb-0">
                        {data?.closed_loans ?? 0}
                      </h5>
                      <h6 className="fw-semibold text-white mb-0 pt-3">
                        UGX {data?.closed_loan_amount?.toLocaleString() ?? 0}{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card border-0 zoom-in bg-danger shadow-none">
              <div className="card-body">
                <div className="text-center">
                  <p className="fw-semibold fs-3 text-white mb-1">
                    Writtern Loans
                  </p>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="fw-semibold text-white mb-0">
                        {data?.flagoff_loans}
                      </h5>
                      <h6 className="fw-semibold text-white mb-0 pt-3">
                        UGX {data?.flagoff_loan_amount?.toLocaleString() ?? 0}{" "}
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-12 d-flex align-items-stretch">
            <div className="card w-100">
              <div className="card-header fw-semibold">Due Loans</div>
              <div className="card-body p-0">
                <Table
                  columns={columns}
                  data={data?.over_due?.slice(1, 50) ?? []}
                  isLoading={isLoading}
                  refetch={refetch}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-12 d-flex align-items-center justify-content-center">
            <div className="card w-100 bg-light-white overflow-hidden shadow-none">
              <div className="card-body p-0">
                <div className="row justify-content-center">
                  <div className="col-md-6 card">
                    <div className="card-header h5 text-center">
                      Clients By Gender
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <div
                        style={{
                          height: "250px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {clients && (
                          <ClientsPieChart transactions={data?.clientsArray} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchManagerDashboard;
