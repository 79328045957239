import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";

const CashBook = () => { 
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [dates, setDates] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [applicationAmount, setApplicationAmount] = useState([]);
  const [applicationFee, setApplicationFee] = useState([]);

  const onQuery = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        `/reports/accounting/cashbook`,
        data,
        {
          signal: controller.signal,
        }
      );
      setDates(response.data.data.dates);
      setIncomes(response.data.data.data.incomes);
      setDeposits(response.data.data.data.payments);
      setExpenses(response.data.data.data.expenses);
      setApplicationAmount(response.data.data.data.application_amounts);
      setApplicationFee(response.data.data.data.application_fees);
      progress.finish();
      setDisabled(false);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const datacredit = [...incomes, ...deposits, ...applicationFee];
  const datadebit = [...expenses, ...applicationAmount];
  const sumcredit = datacredit
    .filter((data) => data.type === "credit")
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  const sumdebit = datadebit
    .filter((data) => data.type === "debit")
    .map((data) => data.amount)
    .reduce((a, b) => a + b, 0);
  //   console.log(sumcredit)
  const sortedCreditData = datacredit.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  const sortedDebitData = datadebit.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );
  //   console.log(data)
  return (
    <>
      <Toaster />
      <>
        <div className="container-fluid note-has-grid">
          <div className="card bg-light-dark text-white shadow-none position-relative overflow-hidden mt-md-0 mt-5">
            <div className="card-body px-4 py-3">
              <div className="row align-items-center">
                <div className="col-12">
                  <h5 className="fw-semibold mb-8 text-white">
                    Accounting Reports - Cashbook
                  </h5>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link
                          className="text-muted text-decoration-none text-white"
                          to="/dashboard"
                        >
                          Dashboard
                        </Link>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Cash Book
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fuild">
            <div className="card">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header bg-light-dark">
                      <h2 className="h5 p-1 text-bold text-uppercase text-center text-white">
                        Cash Book{" "}
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <form
                            className="row needs-validation"
                            id="generalLegerForm"
                            onSubmit={handleSubmit(onQuery)}
                          >
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select Start Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select Start Date"
                                className="form-control shadow border-1"
                                {...register("start_date", {
                                  required: true,
                                })}
                                style={{ height: "45px" }}
                              />
                              {errors?.start_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select End Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select End Date"
                                className="form-control shadow border-1"
                                {...register("end_date", {
                                  required: true,
                                })}
                                style={{ height: "45px" }}
                              />
                              {errors?.end_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-3 pt-3 mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary text-center rounded-0"
                                disabled={disabled}
                              >
                                {!disabled && "Generate"}{" "}
                                {disabled && "Please Wait ....."}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="mt-3">
                        <table className="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th colSpan="7">
                                <b className="text-right">
                                  From: {dates?.start_date} To:{" "}
                                  {dates?.end_date}
                                </b>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan="5" className="text-white bg-dark">
                                Cr
                              </th>
                            </tr>
                            <tr style={{ backgroundColor: "#F6F9FD" }}>
                              <th>Date</th>
                              <th>Reference No.</th>
                              <th>Vote / Account</th>
                              <th>Comments</th>
                              <th>Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {sortedCreditData?.map(
                              (data, index) =>
                                data?.amount !== 0 && (
                                  <tr key={index}>
                                    <td>
                                      {new Date(data?.date).toLocaleDateString(
                                        "en-US",
                                        options
                                      )}
                                    </td>
                                    <td>{data?.reference}</td>
                                    <td>{data?.account}</td>
                                    <td>{data?.comment}</td>
                                    <td>{data?.amount?.toLocaleString()}</td>
                                  </tr>
                                )
                            )}

                            <tr>
                              <th>Total Credit</th>
                              <th colSpan="3"></th>
                              <th>{sumcredit?.toLocaleString()}</th>
                            </tr>
                          </tbody>
                          <thead>
                            <tr>
                              <th colSpan="5" className="text-white bg-dark">
                                Dr
                              </th>
                            </tr>
                            <tr style={{ backgroundColor: "#F6F9FD" }}>
                              <th>Date</th>
                              <th>Reference No.</th>
                              <th>Account</th>
                              <th>Comments</th>
                              <th>Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {sortedDebitData?.map(
                              (data, index) =>
                                data?.amount !== 0 && (
                                  <tr key={index}>
                                    <td>
                                      {new Date(data?.date).toLocaleDateString(
                                        "en-US",
                                        options
                                      )}
                                    </td>
                                    <td>{data?.reference}</td>
                                    <td>{data?.account}</td>
                                    <td>{data?.comment}</td>
                                    <td>{data?.amount?.toLocaleString()}</td>
                                  </tr>
                                )
                            )}

                            <tr>
                              <th>Total Debit</th>
                              <th colSpan="3"></th>
                              <th>{sumdebit?.toLocaleString()}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default CashBook;
