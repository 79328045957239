import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import Loader from "services/loaders/loader";

const DashboardBranches = ({ roles }) => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const controller = new AbortController();
  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get("/users/branches", {
        signal: controller.signal,
      });
      setBranches(response?.data?.data?.branches);
      // console.log(response?.data);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className=" col-md-8">
          <div className="card card-header text-center">
            <h5 className="fw-semibold mb-4">
              Select Branch To View Branch Dashboard
            </h5>
          </div>
          <div className="card-body p-4">
            {isLoading && (
              <div className="d-flex justify-content-center align-items-center">
                <Loader />
              </div>
            )}
            <div className="row justify-content-center">
              {branches?.map((branch) => (
                <div className="col-md-4 col-sm-6 mb-4" key={branch.id}>
                  <div className="card shadow-lg h-100">
                    <div className="card-header text-center">{branch.name}</div>
                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                      <Link
                        to={`/dashboard/branch/${branch.id}`}
                        className="btn btn-primary"
                      >
                        View Branch
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardBranches;
