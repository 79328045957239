import Logo from "pages/assets/logos/logo-white.png";
import { useState } from "react";
import useLogout from "middlewares/hooks/useLogout";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = ({ isSidebarOpen, handleSidebarToggle, roles }) => {
  const navigate = useNavigate();

  const logout = useLogout();
  const signOut = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      logout();
      localStorage.clear();
      navigate("/");
    }
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const [isDropdownOpenLoansGeneral, setIsDropdownOpenLoansGeneral] =
    useState(false);

  const toggleDropdownLoansGeneral = () => {
    setIsDropdownOpenLoansGeneral(!isDropdownOpenLoansGeneral);
  };

  const [isDropdownOpenLoansBranch, setIsDropdownOpenLoansBranch] =
    useState(false);

  const toggleDropdownLoansBranch = () => {
    setIsDropdownOpenLoansBranch(!isDropdownOpenLoansBranch);
  };

  const [isDropdownOpenReports, setIsDropdownOpenReports] = useState(false);

  const toggleDropdownReports = () => {
    setIsDropdownOpenReports(!isDropdownOpenReports);
  };

  const [isDropdownOpenBranchReports, setIsDropdownOpenBranchReports] = useState(false);

  const toggleDropdownBranchReports = () => {
    setIsDropdownOpenBranchReports(!isDropdownOpenBranchReports);
  };

  const [isDropdownOpenSettings, setIsDropdownOpenSettings] = useState(false);

  const toggleDropdownSettings = () => {
    setIsDropdownOpenSettings(!isDropdownOpenSettings);
  };

  return (
    <>
      <aside className={`left-sidebar sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div>
          <div className="brand-logo d-flex align-items-center justify-content-between">
            <Link to="/dashboard" className="text-nowrap logo-img">
              <img src={Logo} className="dark-logo" width="120" alt="logo" />
            </Link>
            <div
              className="close-btn d-lg-none d-block sidebartoggler cursor-pointer"
              id="sidebarCollapse"
              onClick={handleSidebarToggle}
            >
              <i className="ti ti-x fs-8 text-muted"></i>
            </div>
          </div>

          <nav className="sidebar-nav scroll-sidebar" data-simplebar>
            <ul id="sidebarnav">
              <li className="nav-small-cap active">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">General</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/dashboard"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-aperture"></i>
                  </span>
                  <span className="hide-menu">Dashboard</span>
                </Link>
              </li>
              {(roles.includes(1700352) || roles.includes(10000021)) && (
                <li className={`sidebar-item ${isDropdownOpen ? "open" : ""}`}>
                  <Link
                    to="#"
                    className={`sidebar-link`}
                    onClick={toggleDropdown}
                  >
                    <span className="d-flex">
                      <i className="ti ti-users"></i>
                    </span>
                    <span className="hide-menu">Clients</span>
                    <span className="d-flex ml-5 float-right">
                      <i
                        className={`ti ${
                          isDropdownOpen ? "ti-arrow-down" : "ti-arrow-right"
                        } sidebar-arrow`}
                      ></i>
                    </span>
                  </Link>
                  <ul
                    className={`collapse first-level ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    {roles.includes(1700352) && (
                      <li className="sidebar-item">
                        <Link to="/clients/general" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">General Clients</span>
                        </Link>
                      </li>
                    )}
                    {roles.includes(10000021) && (
                      <li className="sidebar-item">
                        <Link to="/clients/branch" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Branch Clients</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(roles.includes(1004004) || roles.includes(10000041)) && (
                <>
                  <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Loans</span>
                  </li>
                  {roles.includes(1004004) && (
                    <li
                      className={`sidebar-item ${
                        isDropdownOpenLoansGeneral ? "open" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="sidebar-link "
                        onClick={toggleDropdownLoansGeneral}
                      >
                        <span className="d-flex">
                          <i className="ti ti-building-warehouse"></i>
                        </span>
                        <span className="hide-menu">General Loans</span>
                        <span className="d-flex float-right">
                          <i
                            className={`ti ${
                              isDropdownOpenLoansGeneral
                                ? "ti-arrow-down"
                                : "ti-arrow-right"
                            } sidebar-arrow`}
                          ></i>
                        </span>
                      </Link>
                      <ul
                        className={`collapse first-level ${
                          isDropdownOpenLoansGeneral ? "show" : ""
                        }`}
                      >
                        <li className="sidebar-item">
                          <Link
                            to="/applications/general"
                            className="sidebar-link"
                          >
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Loan Applications</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/active/general" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Active Loans</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/overdue/general" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Overdue Loans</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/settled/general" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Settled Loans</span>
                          </Link>
                        </li>

                        <li className="sidebar-item">
                          <Link to="/waived/general" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Waived Off Loans</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/writtern/general" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">
                              Writtern Off Loans
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                  {roles.includes(10000041) && (
                    <li
                      className={`sidebar-item ${
                        isDropdownOpenLoansBranch ? "open" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className="sidebar-link "
                        onClick={toggleDropdownLoansBranch}
                      >
                        <span className="d-flex">
                          <i className="ti ti-building-bank"></i>
                        </span>
                        <span className="hide-menu">Branch Loans</span>
                        <span className="d-flex float-right">
                          <i
                            className={`ti ${
                              isDropdownOpenLoansBranch
                                ? "ti-arrow-down"
                                : "ti-arrow-right"
                            } sidebar-arrow`}
                          ></i>
                        </span>
                      </Link>
                      <ul
                        className={`collapse first-level ${
                          isDropdownOpenLoansBranch ? "show" : ""
                        }`}
                      >
                        <li className="sidebar-item">
                          <Link
                            to="/applications/branch"
                            className="sidebar-link"
                          >
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Loan Applications</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/active/branch" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Active Loans</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/overdue/branch" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Overdue Loans</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/settled/branch" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Settled Loans</span>
                          </Link>
                        </li>

                        <li className="sidebar-item">
                          <Link to="/waived/branch" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">Waived Off Loans</span>
                          </Link>
                        </li>
                        <li className="sidebar-item">
                          <Link to="/writtern/branch" className="sidebar-link">
                            <div className="round-16 d-flex align-items-center justify-content-center">
                              <i className="ti ti-circle"></i>
                            </div>
                            <span className="hide-menu">
                              Writtern Off Loans
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}
                </>
              )}
              {roles.includes(10000100) && (
                <>
                  <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Accounting</span>
                  </li>
                  {roles.includes(100000102) && (
                    <li className="sidebar-item">
                      <Link
                        className="sidebar-link"
                        to="/accounting/expenses"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="ti ti-credit-card"></i>
                        </span>
                        <span className="hide-menu">Expenses</span>
                      </Link>
                    </li>
                  )}
                  {roles.includes(100000108) && (
                    <li className="sidebar-item">
                      <Link
                        className="sidebar-link"
                        to="/accounting/incomes"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="ti ti-businessplan"></i>
                        </span>
                        <span className="hide-menu">Incomes</span>
                      </Link>
                    </li>
                  )}
                  {roles.includes(100000104) && (
                    <li className="sidebar-item">
                      <Link
                        className="sidebar-link"
                        to="/accounting/fixed-deposits"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="ti ti-moneybag"></i>
                        </span>
                        <span className="hide-menu">Fixed Deposits</span>
                      </Link>
                    </li>
                  )}
                  {roles.includes(100000107) && (
                    <li className="sidebar-item">
                      <Link
                        className="sidebar-link"
                        to="/accounting/transactions"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="ti ti-currency-litecoin"></i>
                        </span>
                        <span className="hide-menu">Transactions</span>
                      </Link>
                    </li>
                  )}
                  {roles.includes(100000101) && (
                    <li className="sidebar-item">
                      <Link
                        className="sidebar-link"
                        to="/accounting/assets"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="ti ti-asset"></i>
                        </span>
                        <span className="hide-menu">Assets</span>
                      </Link>
                    </li>
                  )}
                </>
              )}
              <>
                {/* <li className="nav-small-cap">
                  <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                  <span className="hide-menu">Client System Management</span>
                </li>
                <li className="sidebar-item">
                  <Link
                    className="sidebar-link"
                    to="/clients-system/ussd"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-mobiledata"></i>
                    </span>
                    <span className="hide-menu">USSD Payments</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    className="sidebar-link"
                    to="/clients-system/mobile-payments"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-moneybag"></i>
                    </span>
                    <span className="hide-menu">Mobile Payments</span>
                  </Link>
                </li>
                <li className="sidebar-item">
                  <Link
                    className="sidebar-link"
                    to="/clients-system/blocked-clients"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-device-audio-tape"></i>
                    </span>
                    <span className="hide-menu">Blocked Clients</span>
                  </Link>
                </li> */}
              </>
              {roles.includes(1001) && (
                <>
                  <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Reports</span>
                  </li>
                  <li
                    className={`sidebar-item ${
                      isDropdownOpenReports ? "open" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="sidebar-link"
                      onClick={toggleDropdownReports}
                    >
                      <span className="d-flex">
                        <i className="ti ti-file-analytics"></i>
                      </span>
                      <span className="hide-menu">General Reports</span>
                      <span className="d-flex">
                        <i
                          className={`ti ${
                            isDropdownOpenReports
                              ? "ti-arrow-down"
                              : "ti-arrow-right"
                          } sidebar-arrow`}
                        ></i>
                      </span>
                    </Link>
                    <ul
                      className={`collapse first-level ${
                        isDropdownOpenReports ? "show" : ""
                      }`}
                    >
                      {/* <li className="sidebar-item">
                        <Link
                          to="/reports/daily-reports"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Daily Reports</span>
                        </Link>
                      </li> */}
                      <li className="sidebar-item">
                        <Link to="/reports/accounting" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Accounting Reports</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link to="/reports/loans" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Loans Reports</span>
                        </Link>
                      </li>
                      {/* <li className="sidebar-item">
                        <Link to="/reports/user" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">
                            User Summary Reports
                          </span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link
                          to="/reports/communication"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">
                            Communication Reports
                          </span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link
                          to="/reports/performance"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">
                            Visual Performance Reports
                          </span>
                        </Link>
                      </li>

                      <li className="sidebar-item">
                        <Link to="/reports/clients" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Client Reports</span>
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li
                    className={`sidebar-item ${
                      isDropdownOpenReports ? "open" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="sidebar-link"
                      onClick={toggleDropdownBranchReports}
                    >
                      <span className="d-flex">
                        <i className="ti ti-file-zip"></i>
                      </span>
                      <span className="hide-menu">Branch Reports</span>
                      <span className="d-flex">
                        <i
                          className={`ti ${
                            isDropdownOpenBranchReports
                              ? "ti-arrow-down"
                              : "ti-arrow-right"
                          } sidebar-arrow`}
                        ></i>
                      </span>
                    </Link>
                    <ul
                      className={`collapse first-level ${
                        isDropdownOpenBranchReports ? "show" : ""
                      }`}
                    >
                      {/* <li className="sidebar-item">
                        <Link
                          to="/reports/daily-reports"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Daily Reports</span>
                        </Link>
                      </li> */}
                      <li className="sidebar-item">
                        <Link to="/reports/accounting" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Accounting Reports</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link to="/reports/loans" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Loans Reports</span>
                        </Link>
                      </li>
                      {/* <li className="sidebar-item">
                        <Link to="/reports/user" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">
                            User Summary Reports
                          </span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link
                          to="/reports/communication"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">
                            Communication Reports
                          </span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link
                          to="/reports/performance"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">
                            Visual Performance Reports
                          </span>
                        </Link>
                      </li>

                      <li className="sidebar-item">
                        <Link to="/reports/clients" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Client Reports</span>
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                </>
              )}
              {roles.includes(1004) && (
                <>
                  <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Communication</span>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/communication/bulk-sms"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-device-mobile-message"></i>
                      </span>
                      <span className="hide-menu">Bulk SMS</span>
                    </Link>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/communication/emails"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-inbox"></i>
                      </span>
                      <span className="hide-menu">System Emails</span>
                    </Link>
                  </li>
                </>
              )}
              {roles.includes(1005) && (
                <>
                  <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">System Health</span>
                  </li>
                  <li className="sidebar-item">
                    <Link
                      className="sidebar-link"
                      to="/system/health"
                      aria-expanded="false"
                    >
                      <span>
                        <i className="ti ti-devices"></i>
                      </span>
                      <span className="hide-menu">System Settings</span>
                    </Link>
                  </li>
                </>
              )}
              <li className="nav-small-cap">
                <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                <span className="hide-menu">Accounts</span>
              </li>
              <li className="sidebar-item">
                <Link
                  className="sidebar-link"
                  to="/accounts/profile"
                  aria-expanded="false"
                >
                  <span>
                    <i className="ti ti-user-circle"></i>
                  </span>
                  <span className="hide-menu">Account Profile</span>
                </Link>
              </li>
              {roles.includes(1002) && (
                <li className="sidebar-item">
                  <Link
                    className="sidebar-link"
                    to="/accounts/users"
                    aria-expanded="false"
                  >
                    <span>
                      <i className="ti ti-users"></i>
                    </span>
                    <span className="hide-menu">User Accounts</span>
                  </Link>
                </li>
              )}
              {roles.includes(1003) && (
                <>
                  <li className="nav-small-cap">
                    <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                    <span className="hide-menu">Manage</span>
                  </li>
                  <li
                    className={`sidebar-item ${
                      isDropdownOpenSettings ? "open" : ""
                    }`}
                  >
                    <Link
                      to="#"
                      className="sidebar-link "
                      onClick={toggleDropdownSettings}
                    >
                      <span className="d-flex">
                        <i className="ti ti-settings-bolt"></i>
                      </span>
                      <span className="hide-menu">Settings</span>
                      <span className="d-flex">
                        <i
                          className={`ti ${
                            isDropdownOpenSettings
                              ? "ti-arrow-down"
                              : "ti-arrow-right"
                          } sidebar-arrow`}
                        ></i>
                      </span>
                    </Link>
                    <ul
                      className={`collapse first-level ${
                        isDropdownOpenSettings ? "show" : ""
                      }`}
                    >
                      <li className="sidebar-item">
                        <Link
                          to="/settings/clients/groups"
                          className="sidebar-link"
                        >
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Client Groups</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link to="/settings/branches" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Branches</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link to="/settings/roles" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Roles</span>
                        </Link>
                      </li>
                      <li className="sidebar-item">
                        <Link to="/settings/votes" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Votes</span>
                        </Link>
                      </li>

                      <li className="sidebar-item">
                        <Link to="/settings/activity" className="sidebar-link">
                          <div className="round-16 d-flex align-items-center justify-content-center">
                            <i className="ti ti-circle"></i>
                          </div>
                          <span className="hide-menu">Activity</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
            <div className="container row">
              <button onClick={signOut} className="btn btn-danger">
                <i className="ti ti-logout p-1"></i>
                Logout
              </button>
            </div>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
